import Vue from 'vue';

export type Faq = {
  answer: string
  categories?: string[]
  id: string
  question: string
}

export const listFaqSections = async () => {
  const { data } = await Vue.$axios.get(`/faq`);
  return data as Faq[];
}

export const addSection = async (data: {question: string, answer: string, categories?: string[] }) => {
  await Vue.$axios.post('/faq', data);
}

export const deleteSection = async (id: string) => {
  await Vue.$axios.delete(`/faq/${id}`)
}

export const updateSection = async (id: string, data: {answer: string, question: string, categories?: string[]}) => {
  await Vue.$axios.patch(`/faq/${id}`, data)
}