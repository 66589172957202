

















import Vue from 'vue';
import { Component, Prop } from "vue-property-decorator";

@Component({
  name: 'ConfirmDialog'
})
export default class extends Vue {
  @Prop({ default: false })
  loading: boolean

  @Prop({ default: false })
  show: boolean

  @Prop({ default: '' })
  error: string

  confirm() {
    this.$emit('confirm');
  }

  cancel() {
    this.$emit('cancel');
  }

}
