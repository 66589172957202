












































































import Vue from 'vue';
import { Component, Ref } from "vue-property-decorator";
import { VForm } from "@/lib/types";
import { clone, pick, keys } from 'ramda';

import ConfirmDialog from '@/components/ConfirmDialog.vue'
import ClassesTable from '@/components/ClassesTable.vue';
import TransactionsTable from '@/components/TransactionsTable.vue';

import { Blog } from '@/store/modules';

@Component({
    name: 'BlogDetails',
    components: {
        ConfirmDialog,
        ClassesTable,
        TransactionsTable
    }
})
export default class extends Vue {
    @Ref("form") readonly form!: VForm;

    model: any = {};
    isLoading = true;
    isLoadingEdit = false;
    isLoadingDelete = false;
    error = '';
    editError = '';
    errorDelete = '';
    showEditDialog = false;
    showConfirmDialog = false;
    valid = true;
    editModel: any = {};

    mounted() {
        this.refresh();
    }

    get postSlug() {
        return this.$route.params.id;
    }

    get userRole() {
        return this.model && this.model.role;
    }

    async edit() {
        this.isLoadingEdit = true
        this.editError = '';

        if (this.form.validate()) {
            try {
                // await Users.editUser({ id: this.userId, payload: this.editModel });
                this.showEditDialog = false;
            } catch (err) {
                this.editError = err.message;
            }
        }

        this.isLoadingEdit = false
    }

    cancelEdit() {
        this.showEditDialog = false;
        this.editError = '';
        this.setEditModel();
    }

    setEditModel() {
        this.editModel = pick(keys(this.editModel), this.model);
    }

    async deleteUser() {
        this.isLoadingDelete = true;
        this.errorDelete = '';

        try {
            await Blog.deleteBlogPosts(this.postSlug)
            this.showConfirmDialog = false;
            this.$router.go(-1);
        } catch (err) {
            this.errorDelete = err.message;
        }

        this.isLoadingDelete = false;
    }

    async refresh() {
        this.isLoading = true;
        console.log('this.postSlug ', this.postSlug)
        try {
            const post = await Blog.getBlogPost(this.postSlug);
            console.log('post ', post)
            this.model = clone(post);
            this.setEditModel();
        } catch (err) {
            this.error = err.message;
        }
        this.isLoading = false;
    }
}
