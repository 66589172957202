

















import { Vue, Component, Watch } from 'vue-property-decorator';
import { clone } from 'ramda';

import { Users } from '@/store/modules';

@Component
export default class extends Vue {
  headers = [
    { text: '', value: 'avatarUrl' },
    { text: 'Name', value: 'name' },
    { text: 'Email', value: 'email' },
    { text: 'Role', value: 'role' },
    { text: 'Joined', value: 'registeredAt' },
    { text: 'Last Active', value: 'lastActivityDate' },
  ];
  isLoading = false;
  totalUsers = 0;
  options: any = {
    sortBy: ['registeredAt'],
    sortDesc: [true]
  };
  users: any[] = [];
  error = '';

  mounted() {
    this.refresh();
  }

  @Watch('options', { deep: true })
  onOptionsChanged() {
    this.refresh();
  }

  async refresh() {
    try {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
  
      const { items, count } = await Users.listUsers({
        limit: itemsPerPage,
        offset: (page - 1) * itemsPerPage,
        sort: sortBy[0],
        sortDesc: sortDesc[0]
      });
  
      this.users = clone(items);
      this.totalUsers = count;
    } catch (err) {
      this.error = err.message;
    }
  }
}
