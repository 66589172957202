<template lang="pug">
  div
    v-expand-transition
      v-alert(v-if="error" type="error" text) {{ error | APIError }}
    v-data-table(
      :headers="headers"
      :items="coupons"
      :options.sync="options"
      :server-items-length="totalCoupons"
      :loading="isLoading"
      @click:row="item => this.$router.push(`/coupons/${item.id}`)"
    )
      template(v-slot:item.createdAt="{ value }")
        div {{ value | formatDate }}
      template(v-slot:item.expiresAt="{ value }")
        div {{ value | formatDate }}
</template>

<script>
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Coupons } from '@/store/modules';

@Component({
  name: 'CouponsTable'
})
export default class CouponsTable extends Vue {
  headers = [
    { text: 'Code', value: 'code' },
    { text: 'Created at', value: 'createdAt' },
    { text: 'Expired at', value: 'expiresAt' },
    { text: 'Stripe ID', value: 'stripeId' },
  ];
  isLoading = false;
  totalCoupons = 0;
  coupons = [];
  options = {};
  error = '';

  mounted() {
    this.refresh();
  }

  @Watch('options', { deep: true })
  onOptionsChanged() {
    this.refresh();
  }

  async refresh() {
    this.isLoading = true;
    try {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;

      const { items, count } = await Coupons.listCoupons({
        itemsPerPage,
        offset: (page - 1) * itemsPerPage,
        sortBy,
        sortDesc
      });

      this.coupons = items;
      this.totalCoupons = count;
    } catch (error) {
      this.error = error.message;
    }
    this.isLoading = false;
  }
}
</script>
