export const errors = {
  INTERNAL_ERROR: 'Unknown server error. Please try again or contact developers.',
  NOT_FOUND: 'Requested resource was not found or was deleted.',
  USER_NOT_FOUND: 'User not found.',
  EMAIL_TAKEN: 'This email is already taken.',
  BAD_REQUEST: 'Bad request was sent to the server. Please check if no data is missing or contact developers.',
  MALFORMED_TOKEN: 'Internal authorization error. Please contact developers.',
  UNAUTHORIZED: 'You are not authorized to perform such ',
  FORBIDDEN: "You don't have enough permission to perform such action or receive information about the requested entity.",
  FAILED_TO_SEND_EMAIL: 'Failed to send email.',
} as { [key: string]: string };

export const getErrorMessage = (message: string) => {
  if (!errors[message]) {
    return message;
  }

  return errors[message];
};
