var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"mb-6"},[_vm._v("Users")]),_c('p',[_vm._v("Here you can find information about students and teachers registered in SkillToon.")]),_c('v-expand-transition',[(_vm.error)?_c('v-alert',{attrs:{"type":"error","text":""}},[_vm._v(_vm._s(_vm._f("APIError")(_vm.error)))]):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users,"options":_vm.options,"server-items-length":_vm.totalUsers,"loading":_vm.isLoading},on:{"update:options":function($event){_vm.options=$event},"click:row":function (item) { return this$1.$router.push(("/users/" + (item.id))); }},scopedSlots:_vm._u([{key:"item.registeredAt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm._f("formatDate")(value)))])]}},{key:"item.lastActivityDate",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm._f("formatDate")(value)))])]}},{key:"item.avatarUrl",fn:function(ref){
var value = ref.value;
return [_c('v-avatar',{attrs:{"size":40}},[(value)?_c('v-img',{attrs:{"src":value}}):_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-account-circle")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }