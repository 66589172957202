






















import { Vue, Component, Watch } from 'vue-property-decorator';
import { clone } from 'ramda';

import { Teachers } from '@/store/modules';

@Component
export default class extends Vue {
  headers = [
    { text: '', value: 'avatarUrl', sortable: false },
    { text: 'Name', value: 'name' },
    { text: 'Classes', value: 'totalClasses' },
    { text: 'Rating', value: 'avgRating' },
    { text: 'Ratings', value: 'ratingsCount' },
    { text: 'Created', value: 'createdAt' },
  ];
  isLoading = false;
  totalTeachers = 0;
  options: any = {
    sortBy: ['createdAt'],
    sortDesc: [true]
  };
  teachers: any[] = [];
  error = '';

  mounted() {
    this.refresh();
  }

  @Watch('options', { deep: true })
  onOptionsChanged() {
    this.refresh();
  }

  async refresh() {
    try {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
  
      const { items, count } = await Teachers.listTeachers({
        limit: itemsPerPage,
        offset: (page - 1) * itemsPerPage,
        sort: sortBy,
        sortDesc: sortDesc
      });
  
      this.teachers = clone(items);
      this.totalTeachers = count;
    } catch (err) {
      this.error = err.message;
    }
  }
}
