import Vue from 'vue';
import { Module, VuexModule, Action } from 'vuex-module-decorators'

export type EditUserModel = {
  email: string
  name: string
  details: {
    youtube?: string
    instagram?: string
    facebook?: string
    twitter?: string
    bio?: string
    location?: string
    termsConditions?: boolean
    privacyPolicy?: boolean
    marketing?: boolean
    onboardingFinished?: boolean
  }
  role?: string
}

export type User = {
  id: string
  name: string
  email: string
}

@Module({ namespaced: true, name: 'users', preserveState: true })
class Users extends VuexModule {

  @Action({ rawError: true })
  async listUsers(data: { limit: number, offset: number, role?: string, sort?: string, sortDesc?: boolean }): Promise<{
    items: User[]
    count: number
  }> {
    const query = new URLSearchParams();

    if (data.limit) {
      query.append('limit', `${data.limit}`);
    }

    if (data.offset) {
      query.append('offset', `${data.offset}`);
    }

    if (data.role) {
      query.append('role', `${data.role}`);
    }

    if (data.sort) {
      query.append('sort', `${data.sort}`);
    }

    if (data.sortDesc) {
      query.append('sortDesc', `${data.sortDesc}`);
    }

    const response = await Vue.$axios.get(`/users?${query.toString()}`);
    return response.data;
  }

  @Action({ rawError: true })
  async getUser(data: { id: string }) {
    const response = await Vue.$axios.get(`/users/${data.id}`);
    return response.data;
  }

  @Action({ rawError: true })
  async editUser(data: { id: string, payload: EditUserModel }) {
    return await Vue.$axios.patch(`/users/${data.id}`, data.payload);
  }

  @Action({ rawError: true })
  async deleteUser(data: { id: string }) {
    return await Vue.$axios.delete(`/users/${data.id}`);
  }
}

export default Users;
