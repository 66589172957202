
































































































































































































import Vue from 'vue';
import { Component, Ref} from "vue-property-decorator";
import { VForm } from "@/lib/types";
import { clone, pick, keys } from 'ramda';

import ConfirmDialog from '@/components/ConfirmDialog.vue'
import ClassesTable from '@/components/ClassesTable.vue';
import TransactionsTable from '@/components/TransactionsTable.vue';
import DatePicker from '@/components/DatePicker.vue';

import { Teachers } from '@/store/modules';

@Component({
  name: 'Teacher',
  components: {
    ConfirmDialog,
    ClassesTable,
    TransactionsTable,
    DatePicker
  }
})
export default class extends Vue {
  @Ref("form") readonly form!: VForm;

  model: any = {};
  isLoading = true;
  isLoadingEdit = false;
  isLoadingDelete = false;
  error = '';
  editError = '';
  errorDelete = '';
  showEditDialog = false;
  showConfirmDialog = false;
  valid = true;
  editModel: any = {
    name: null,
    about: null,
    active: null,
    avatarUrl: null,
    details: {
      credentials: null,
      experienceDesc: null,
      youtube: null,
      instagram: null,
      facebook: null,
      twitter: null
    },
    payoutInfo: null
  };
  editedPayoutDetails = false;

  mounted() {
    this.refresh();
  }

  get teacherId() {
    return this.$route.params.id;
  }

  async edit(validate = true) {
    this.isLoadingEdit = true
    this.editError = '';
    
    if ((validate && this.form.validate()) || !validate) {
      try{
        await Teachers.editTeacher({ id: this.teacherId, payload: this.editModel });
        this.showEditDialog = false;
        this.refresh();
      } catch(err) {
        this.editError = err.message;
      }
    }

    this.editedPayoutDetails = false;
    this.isLoadingEdit = false
  }

  cancelEdit() {
    this.showEditDialog = false;
    this.editError = '';
    this.setEditModel();
  }

  setEditModel() {
    this.editModel = pick(keys(this.editModel), this.model);
  }

  async deleteTeacher() {
    this.isLoadingDelete = true;
    this.errorDelete = '';

    try {
      await Teachers.deleteTeacher({ id: this.teacherId })
      this.showConfirmDialog = false;
      this.$router.go(-1);
    } catch(err) {
      this.errorDelete = err.message;
    }

    this.isLoadingDelete = false;
  }

  async refresh() {
    this.isLoading = true;
    try {
      const teacher = await Teachers.getTeacher({ id: this.teacherId });
      this.model = clone(teacher);
      this.setEditModel();
    } catch (err) {
      this.error = err.message;
    }
    this.isLoading = false;
  }
}
