import axios from 'axios';
import Vue from 'vue';
// import Vue from 'vue';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'

const API_URL = process.env.VUE_APP_API_URL || 'http://localhost:3000';

export enum UserRole {
  'ADMIN' = 'ADMIN', 'USER' = 'USER'
}
export interface User {
  id: string
  email: string
  name: string
  avatarUrl?: string
  role: UserRole
  registeredAt: Date
  lastActivityDate: Date
  deletedAt?: Date
  updatedAt: Date
  createdAt: Date
}
@Module({ namespaced: true, name: 'auth', preserveState: true  })
class Auth extends VuexModule {
  user: User | {} = {}
  error = ''
  token = ''

  @Mutation
  setUser(payload: User) {
    this.user = Object.assign({}, payload)
  }

  @Mutation
  setError(payload: string) {
    this.error = payload
  }

  @Mutation
  setToken(payload: string) {
    this.token = payload
  }

  @Mutation
  removeToken() {
    this.token = ''
  }

  @Mutation
  resetState() {
    this.user = {}
    this.error = ''
    this.token = ''
  }

  get loggedIn() {
    return !!this.token
  }

  @Action({ rawError: true })
  async doLogin(data: { email: string, password: string }) {
    try {
      const response = await axios.post(`${API_URL}/auth/login`, data)
      const responseData = response.data
      this.setUser(responseData.user)
      this.setToken(responseData.token)
    } catch (err) {
      const errorMessage = (err.response && err.response.data && err.response.data.message) || err.message
      throw new Error(errorMessage);
    }
  }

  @Action({ rawError: true })
  async getUploadUrl(data: { type: 'blog_image' | 'class_image', id: string }) {
    const response = await Vue.$axios.get(`/upload?type=${data.type}&id=${data.id}`);
    return response.data.url;
  }

  @Action({ rawError: true })
  async doLogout(data?: { redirectUrl?: string, authError?: boolean, errorCode?: string }) {
    this.resetState();

    if (data && (data.redirectUrl || data.authError || data.errorCode)) {
      const queryParams = new URLSearchParams();

      if (data.redirectUrl) {
        queryParams.append('redirectUrl', data.redirectUrl);
      }

      if (data.authError) {
        queryParams.append('authError', 'true');
      }

      if (data.errorCode) {
        queryParams.append('errorCode', data.errorCode);
      }

      window.location.href = `/login?${queryParams}`;
      return;
    }
    window.location.href = '/login';
  }

  @Action({ rawError: true })
  async resetStateAction() {
    this.resetState()
  }
}

export default Auth;
