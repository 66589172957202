var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-expand-transition',[(_vm.error)?_c('v-alert',{attrs:{"type":"error","text":""}},[_vm._v(_vm._s(_vm._f("APIError")(_vm.error)))]):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.classes,"options":_vm.options,"server-items-length":_vm.totalClasses,"loading":_vm.isLoading},on:{"update:options":function($event){_vm.options=$event},"click:row":function (item) { return this$1.$router.push(("/classes/" + (item.id))); }},scopedSlots:_vm._u([{key:"item.teacher",fn:function(ref){
var value = ref.value;
return [_c('router-link',{staticClass:"hover",attrs:{"to":("/teachers/" + (value.id))}},[_c('avatar',{attrs:{"url":value.avatarUrl,"label":value.name,"size":32}})],1)]}},{key:"item.avgRating",fn:function(ref){
var value = ref.value;
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(((_vm.classes[index].avgRating) + " (" + (_vm.classes[index].ratingsCount) + ")")))])]}},{key:"item.createdAt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm._f("formatDate")(value)))])]}},{key:"item.isHidden",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm._f("formatBoolean")(value)))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }