import Vue from 'vue';
import { Action, Module, VuexModule } from 'vuex-module-decorators'

@Module({ namespaced: true, name: 'blog', preserveState: true})
class Blog extends VuexModule {
    
    @Action({ rawError: true })
    async getBlogPosts() {
        const response = await Vue.$axios.get(`/posts`);
        return response.data;
    }

    @Action({ rawError: true })
    async addBlogPosts(data: any) {

        const jsonData = {
            img: data.image,
            title: data.title,
            description: data.description,
            date: data.date,
            category: data.category,
            author: 'SkillToon'
        }
        const response = await Vue.$axios.post(`/posts`, jsonData);
        return response.data;
    }

    @Action({ rawError: true })
    async deleteBlogPosts(id: string) {
        const response = await Vue.$axios.delete(`/posts/${id}`);
        return response.data;
    }

    @Action({ rawError: true })
    async getBlogPost(id: string) {
        const response = await Vue.$axios.get(`/posts/${id}`);
        return response.data;
    }
}

export default Blog