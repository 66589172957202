
























import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { Classes } from "@/store/modules";
import Avatar from "@/components/Avatar.vue";

@Component({
  components: {
    Avatar,
  },
})
export default class extends Vue {
  @Prop() teacherId?: string;
  @Prop() userId?: string;
  headers = [
    { text: "Name", value: "name" },
    { text: "Fee", value: "fee" },
    { text: "Rating", value: "avgRating" },
    { text: "Teacher", value: "teacher", width: "180" },
    { text: "Created", value: "createdAt" },
    { text: "Hidden", value: "isHidden" },
  ];
  isLoading = false;
  totalClasses = 0;
  options: any = {};
  classes: any[] = [];
  error = "";

  mounted() {
    this.refresh();
  }

  @Watch("options", { deep: true })
  onOptionsChanged() {
    this.refresh();
  }

  async refresh() {
    this.isLoading = true;
    try {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;

      const { items, count } = await Classes.listClasses({
        limit: itemsPerPage,
        offset: (page - 1) * itemsPerPage,
        teacherId: this.teacherId,
        userId: this.userId,
        sort: sortBy,
        sortDesc,
      });

      this.classes = items;
      this.totalClasses = count;
      this.isLoading = false;
    } catch (err) {
      this.error = err.message;
      this.isLoading = false;
    }
  }
}
