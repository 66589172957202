<template lang="pug">
  div
    h3.mb-6 Add Coupon section
    v-form(v-model="valid" ref="form" lazy-validation)
      v-row(no-gutters justify="space-between" align="center")
        v-col.pa-2(cols="6")
          v-textarea(
            required
            :rules="[required]"
            label="Name*"
            outlined
            auto-grow
            rows="1"
            dense
            v-model="coupon.name"
            :disabled="isDisabled"
            hint="This will appear on customers' receipts and invoices."
          )
        v-col.pa-2(cols="6")
          v-textarea(
            required
            :rules="[required]"
            label="Code*"
            outlined
            auto-grow
            rows="1"
            dense
            v-model="coupon.code"
            :disabled="isDisabled"
            hint="This code is case-insensitive and must be unique across all active promotion codes for any customers."
          )
      v-row(no-gutters justify="space-between" align="center")
        v-col(cols="12")
          span Fill correct discount value, fill percentage discount or amount and currency
        v-col.pa-2(cols="4")
          v-text-field(
            type="number"
            label="Percentage off"
            outlined
            v-model="coupon.percentOff"
            dense
            :disabled="isDisabled"
          )
        v-col.pa-2(cols="4")
          v-text-field(
            type="number"
            label="Amount off"
            outlined
            v-model="coupon.amountOff"
            dense
            :disabled="isDisabled"
          )
        v-col.pa-2(cols="4")
          v-autocomplete(
            outlined
            clearable
            dense
            label="Currency"
            item-value="value"
            item-text="label"
            :items="currencies"
            v-model="coupon.currency"
            :disabled="isDisabled"
          )
      v-row(no-gutters justify="space-between" align="center")
        v-col.pa-2(cols="4")
          v-autocomplete(
            outlined
            :rules="[required]"
            clearable
            dense
            label="Durations*"
            item-value="value"
            item-text="label"
            :items="durations"
            v-model="coupon.duration"
            :disabled="isDisabled"
            hint="For subscriptions and customers, this determines how long this coupon will apply once redeemed. One-off invoices accept both \"once\" and \"forever\" coupons."
          )
        v-col.pa-2(cols="4")
          v-text-field(
            type="number"
            label="Duration in months"
            outlined
            v-model="coupon.durationInMonths"
            dense
            :disabled="isDisabled"
          )
        v-col.pa-2(cols="4")
          DatePicker(
            v-model="coupon.redeemBy"
            outlined
            dense
            label="Redeem by"
            :disabled="isDisabled"
          )
      v-row(no-gutters justify="space-between" align="center")
        v-col.pa-2(cols="6")
          v-text-field(
            type="number"
            label="Minimum amount"
            outlined
            v-model="coupon.minimumAmount"
            dense
            :disabled="isDisabled"
          )
        v-col.pa-2(cols="6")
          v-autocomplete(
            outlined
            clearable
            dense
            label="Minimum amount currency"
            item-value="value"
            item-text="label"
            :items="currencies"
            v-model="coupon.minimumAmountCurrency"
            :disabled="isDisabled"
          )
        v-col.pa-2(cols="6")
          v-checkbox(
            v-model="coupon.firstTimeTransaction"
            label="Allow only for first time payment"
            color="black"
            :disabled="isDisabled"
            hint="This code will only be eligible for customers who never made a purchase"
          )
        v-col.pa-2(cols="6")
          v-text-field(
            type="number"
            label="Max possible redemptions"
            outlined
            v-model="coupon.maxRedemptions"
            dense
            :disabled="isDisabled"
          )
      v-row(no-gutters justify="space-between" align="center")
        v-col.pa-2(cols="6")
          v-checkbox(
            v-model="coupon.showPopup"
            label="Show popup on home page"
            color="black"
            :disabled="isDisabled"
          )
      v-row(no-gutters justify="space-between" align="center")
        v-col(cols="6")
          v-btn(text color="default" elevation="0" @click="$router.go(-1)" width="100%")
            v-icon mdi-ticket
            span Cancel
        v-col(cols="6")
          v-btn(text color="primary" elevation="0" @click="submitForm()" width="100%")
            v-icon mdi-ticket
            span Create coupon
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import DatePicker from '@/components/DatePicker.vue';
import { CouponCurrencyEnum, CouponDurationEnum } from '@/store/coupons';
import { Coupons } from '@/store/modules';

@Component({
  name: 'CouponsForm',
  components: {
    DatePicker
  }
})
export default class CouponsForm extends Vue {
  isDisabled = false;
  valid = true;
  error = '';
  coupon = {};

  required = v => `${v}` !== '' || 'This field is required';

  async submitForm() {
    if (!this.$refs.form.validate()) {
      return;
    }

    this.isDisabled = true;
    try {
      const result = await Coupons.createCoupon(this.coupon);
      this.$router.go(`/coupons/${result.id}`);
      this.$refs.form.reset();
    } catch (error) {
      this.error = error.message;
    }
    this.isDisabled = false;
  }

  get currencies() {
    return [
      { value: CouponCurrencyEnum.USD, label: 'USD' }
    ];
  }

  get durations() {
    return [
      { value: CouponDurationEnum.FOREVER, label: 'Forever' },
      { value: CouponDurationEnum.REPEATING, label: 'Repeating' },
      { value: CouponDurationEnum.ONCE, label: 'Once' }
    ];
  }
}
</script>
