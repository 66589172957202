











































































































































































import Vue from 'vue';
import { Component, Ref} from "vue-property-decorator";
import { VForm } from "@/lib/types";
import { clone, pick, keys } from 'ramda';

import ConfirmDialog from '@/components/ConfirmDialog.vue'
import ClassesTable from '@/components/ClassesTable.vue';
import TransactionsTable from '@/components/TransactionsTable.vue';

import { Users } from '@/store/modules';

@Component({
  name: 'User',
  components: {
    ConfirmDialog,
    ClassesTable,
    TransactionsTable
  }
})
export default class extends Vue {
  @Ref("form") readonly form!: VForm;

  model: any = {};
  isLoading = true;
  isLoadingEdit = false;
  isLoadingDelete = false;
  error = '';
  editError = '';
  errorDelete = '';
  showEditDialog = false;
  showConfirmDialog = false;
  valid = true;
  editModel: any = {
    name: '',
    email: '',
    role: '',
    details: {
      youtube: '',
      instagram: '',
      facebook: '',
      twitter: '',
      bio: '',
      location: '',
      onboardingFinished: false
    }
  };

  mounted() {
    this.refresh();
  }

  get userId() {
    return this.$route.params.id;
  }

  get userRole() {
    return this.model && this.model.role;
  }

  async edit() {
    this.isLoadingEdit = true
    this.editError = '';
    
    if (this.form.validate()) {
      try{
        await Users.editUser({ id: this.userId, payload: this.editModel });
        this.showEditDialog = false;
      } catch(err) {
        this.editError = err.message;
      }
    }

    this.isLoadingEdit = false
  }

  cancelEdit() {
    this.showEditDialog = false;
    this.editError = '';
    this.setEditModel();
  }

  setEditModel() {
    this.editModel = pick(keys(this.editModel), this.model);
  }

  async deleteUser() {
    this.isLoadingDelete = true;
    this.errorDelete = '';

    try {
      await Users.deleteUser({ id: this.userId })
      this.showConfirmDialog = false;
      this.$router.go(-1);
    } catch(err) {
      this.errorDelete = err.message;
    }

    this.isLoadingDelete = false;
  }

  async refresh() {
    this.isLoading = true;
    try {
      const user = await Users.getUser({ id: this.userId });
      this.model = clone(user);
      this.model.details.marketing = true
      this.setEditModel();
    } catch (err) {
      this.error = err.message;
    }
    this.isLoading = false;
  }
}
