










import Vue from 'vue';
import { Component, Prop } from "vue-property-decorator";

@Component({
  name: 'Avatar'
})
export default class extends Vue {
  @Prop({ default: '' })
  url: string

  @Prop({ default: '' })
  label: string

  @Prop({ default: 'mdi-account-circle' })
  icon: string
  
  @Prop({ default: '40' })
  size: string

}
