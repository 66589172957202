import Vue from 'vue';
import { Action, Module, VuexModule } from 'vuex-module-decorators';

export enum CouponCurrencyEnum {
  USD = 'usd'
}

export enum CouponDurationEnum {
  ONCE = 'once',
  REPEATING = 'repeating',
  FOREVER = 'forever'
}

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
interface IListCouponsParams {
  itemsPerPage: number
  offset: number
  sortBy?: string
  sortDesc?: boolean
}

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
interface IGetCouponParams {
  id: string
}

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
interface ICreateCouponParams {
  code: string
  maxRedemptions?: number
  firstTimeTransaction: boolean
  minimumAmount?: number
  minimumAmountCurrency: CouponCurrencyEnum
  showPopup: boolean
  percentOff?: number
  amountOff?: number
  currency?: CouponCurrencyEnum
  duration: CouponDurationEnum
  durationInMonth?: number
  name: string
  redeemBy?: Date
}

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
interface IActivateCouponParams {
  id: string
}

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
interface IDeactivateCouponParams {
  id: string
}

@Module({ namespaced: true, name: 'coupons', preserveState: true })
export default class Coupons extends VuexModule {
  @Action({ rawError: true })
  async listCoupons({ itemsPerPage, offset, sortBy, sortDesc }: IListCouponsParams) {
    const query = new URLSearchParams({ limit: `${itemsPerPage}`, offset: `${offset}` });

    if (sortBy) {
      query.append('sort', sortBy);
    }

    if (sortDesc) {
      query.append('sortDesc', `${sortDesc}`);
    }

    const { data } = await Vue.$axios.get(`/codes?${query.toString()}`);
    return data;
  }

  @Action({ rawError: true })
  async getCoupon({ id }: IGetCouponParams) {
    const { data } = await Vue.$axios.get(`/codes/${id}`);
    return data;
  }

  @Action({ rawError: true })
  async createCoupon(coupon: ICreateCouponParams) {
    Object.keys(coupon).forEach(key => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      if (coupon[key] === null || coupon[key] === '') delete coupon[key];
    });

    ['amountOff', 'percentOff', 'durationInMonth', 'maxRedemptions', 'minimumAmount'].forEach(key => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      if (coupon[key]) coupon[key] = +coupon[key];
    });

    if (coupon.amountOff) {
      coupon.amountOff = coupon.amountOff * 100;
    }

    const { data } = await Vue.$axios.post(`/codes`, coupon);
    return data;
  }

  @Action({ rawError: true })
  async activateCoupon({ id }: IActivateCouponParams) {
    if (!id) {
      throw new Error(`Parameter ID is required !`);
    }

    await Vue.$axios.patch(`/codes/${id}/activate`);
  }

  @Action({ rawError: true })
  async deactivateCoupon({ id }: IDeactivateCouponParams) {
    if (!id) {
      throw new Error(`Parameter ID is required !`);
    }

    await Vue.$axios.patch(`/codes/${id}/deactivate`);
  }
}