<template lang="pug">
  div
    div
      v-btn.mb-6(text @click="$router.go(-1)")
        v-icon mdi-arrow-left
        span Back

      v-expand-transition
        v-progress-linear(v-if="isLoading" indeterminate)

      v-expand-transition
        v-alert(v-if="error && !isLoading" type="error" text) {{ error | APIError }}

      v-expand-transition
        div(v-if="!isLoading && !error")
          v-row(align="center")
            v-col.text-center(cols="12" md="4" lg="3")
              v-icon(:size="128") mdi-ticket
            v-col
              v-row
                v-col
                  h2.mb-3 {{ code.coupon.name }}
                v-col.col-auto
                  v-btn(v-if="!code.active" text @click="activateCoupon()")
                    v-icon mdi-plus
                    span Activate
                  v-btn(v-if="code.active" text @click="deactivateCoupon()")
                    v-icon mdi-minus
                    span Deactivate

              h3.mb-3
                strong {{ code.code }}
              h5.mb-3 Created at: {{ code.createdAt }}
              h5.mb-3 Redeem by: {{ code.coupon.redeemBy }}
              h5.mb-3 Times redeem: {{ code.timesRedeemed }}
              h5.mb-3(v-if="code.coupon.percentOff") Discount: {{ code.coupon.percentOff }}%
              h5.mb-3(v-else) Discount: {{ code.coupon.amountOff / 100 }} {{ code.coupon.currency.toUpperCase() }}
              h5.mb-3 Duration: {{ code.coupon.duration }}
              h5.mb-3 Duration in months: {{ code.coupon.durationInMonths }}
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import { clone } from 'ramda';
import { Coupons } from '@/store/modules';

@Component({
  name: 'Coupon',
  components: {}
})
export default class Coupon extends Vue {
  isDisabled = false;
  isLoading = false;
  error = '';
  code = {};

  mounted() {
    this.refresh();
  }

  get couponId() {
    return this.$route.params.id;
  }

  async refresh() {
    this.isLoading = true;
    try {
      const item = await Coupons.getCoupon({ id: this.couponId });
      this.code = clone(item);
    } catch (error) {
      this.error = error.message;
    }
    this.isLoading = false;
  }

  async activateCoupon() {
    this.isDisabled = true;
    try {
      await Coupons.activateCoupon({ id: this.couponId });
      await this.refresh();
    } catch (error) {
      this.error = error.message;
    }
    this.isDisabled = false;
  }

  async deactivateCoupon() {
    this.isDisabled = true;
    try {
      await Coupons.deactivateCoupon({ id: this.couponId });
      await this.refresh();
    } catch (error) {
      this.error = error.message;
    }
    this.isDisabled = false;
  }
}
</script>
