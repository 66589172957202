var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-expand-transition',[(_vm.error)?_c('v-alert',{attrs:{"type":"error","text":""}},[_vm._v(_vm._s(_vm._f("APIError")(_vm.error)))]):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.payments,"options":_vm.options,"server-items-length":_vm.totalPayments,"loading":_vm.isLoading},on:{"update:options":function($event){_vm.options=$event},"click:row":function (item) { return this$1.$router.push(("/payments/" + (item.id))); }},scopedSlots:_vm._u([{key:"item.fullPrice",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v("$"+_vm._s(value / 100))])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.amount / 100)+" "+_vm._s(item.currency))])]}},{key:"item.teacher",fn:function(ref){
var value = ref.value;
return [_c('router-link',{staticClass:"hover",attrs:{"to":("/teachers/" + (value.id))}},[_c('avatar',{attrs:{"url":value.avatarUrl,"label":value.name,"size":32}})],1)]}},{key:"item.user",fn:function(ref){
var value = ref.value;
return [_c('router-link',{staticClass:"hover",attrs:{"to":("/users/" + (value.id))}},[_c('avatar',{attrs:{"url":value.avatarUrl,"label":value.name,"size":32}})],1)]}},{key:"item.class",fn:function(ref){
var value = ref.value;
return [_c('router-link',{staticClass:"hover",attrs:{"to":("/classes/" + (value.id))}},[_vm._v(_vm._s(value.name))])]}},{key:"item.issuedAt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm._f("formatDate")(value)))])]}},{key:"item.paidOutAt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm._f("formatDate")(value)))])]}},{key:"item.paymentProvider",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(value))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }