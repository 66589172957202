
































































































































































































































import Vue from "vue";
import { Component, Ref } from "vue-property-decorator";
import { VForm } from "@/lib/types";
import { clone, pick, keys, omit } from "ramda";

import ConfirmDialog from "@/components/ConfirmDialog.vue";
import Avatar from "@/components/Avatar.vue";

import { Classes, Users, Seats } from "@/store/modules";
import { User } from "@/store/auth";

@Component({
  name: "Class",
  components: {
    ConfirmDialog,
    Avatar,
  },
})
export default class extends Vue {
  @Ref("form") readonly form!: VForm;
  @Ref("addStudentForm") readonly addStudentForm!: VForm;

  meetingsHeaders = [
    { text: "Name", value: "name" },
    { text: "Date", value: "date" },
    { text: "Status", value: "status" },
    { text: "Length (minutes)", value: "length" },
  ];
  studentsHeaders = [
    { text: "", value: "user.avatarUrl" },
    { text: "Name", value: "user.name" },
    { text: "Email", value: "user.email" },
    { text: "Role", value: "user.role" },
    { text: "Joined", value: "user.registeredAt" },
    { text: "Last Active", value: "user.lastActivityDate" },
  ];
  model: any = {};
  isLoading = true;
  isLoadingEdit = false;
  isLoadingConfirm = false;
  error = "";
  editError = "";
  errorConfirm = "";
  showEditDialog = false;
  confirmDialog = false;
  confirmDialogHeader = "";
  confirmDialogContent = "";
  showAddStudentDialog = false;
  addStudentDialogModel: {
    user: User | undefined
  } = {
    user: undefined
  };
  addStudentError: string = "";
  isAddStudentLoading: boolean = false;
  valid = true;
  addStudentValid = true;
  deleteAcion = false;
  editModel: any = {
    name: null,
    maxSeats: null,
    isOnline: null,
    isHidden: null,
    featured: null,
    details: {},
  };
  students: {
    id: string
    fullName: string
  }[] = []

  mounted() {
    this.refresh();
  }

  get classId() {
    return this.$route.params.id;
  }

 requirements() {
    return (
      (this.editModel &&
        this.editModel.details &&
        this.editModel.details.requirements &&
        this.editModel.details.requirements.split("\n")) ||
      []
    );
  }

  async edit() {
    this.isLoadingEdit = true;
    this.editError = "";

    if (this.form.validate()) {
      try {
        const payload = this.model.name === this.editModel.name
          ? {...omit(["name"], this.editModel)}
          : {...this.editModel}
        await Classes.editClass({ id: this.classId, payload: payload });
        this.showEditDialog = false;
        this.refresh();
      } catch (err) {
        this.editError = err.message;
      }
    }

    this.isLoadingEdit = false;
  }

  showConfirmDialog(isDelete: boolean) {
    if (isDelete) {
      this.deleteAcion = true;
      this.confirmDialogHeader = "Delete class";
      this.confirmDialogContent = "Are you sure you want to delete this class?";
    } else {
      this.confirmDialogHeader = "Cancel class";
      this.confirmDialogContent = "Are you sure you want to cancel this class?";
    }

    this.confirmDialog = true;
  }

  cancelEdit() {
    this.showEditDialog = false;
    this.editError = "";
    this.setEditModel();
  }

  cancelAddStudent() {
    this.showAddStudentDialog = false;
    this.addStudentDialogModel.user = undefined;
  }

  async addStudent() {
    this.isAddStudentLoading = true;
    this.addStudentError = "";

    if (this.addStudentForm.validate()) {
      try {
        const id = this.addStudentDialogModel.user!.id
        await Seats.reserveSeatForUser({classId: this.classId, id});
        this.refresh();
      } catch (err) {
        this.editError = err.message;
      }
    }
    
    this.isAddStudentLoading = false;
    this.showAddStudentDialog = false;
  }

  setEditModel() {
    this.editModel = pick(keys(this.editModel), this.model);
  }

  executeClassAction() {
    if (this.deleteAcion) {
      this.deleteClass();
    } else {
      this.cancelClass();
    }
  }

  async deleteClass() {
    this.isLoadingConfirm = true;
    this.errorConfirm = "";

    try {
      await Classes.deleteClass({ id: this.classId });
      this.confirmDialog = false;
      this.$router.go(-1);
    } catch (err) {
      this.errorConfirm = err.message;
    }

    this.isLoadingConfirm = false;
  }

  async cancelClass() {
    this.isLoadingConfirm = true;
    this.errorConfirm = "";

    try {
      await Classes.cancelClass({ id: this.classId });
      this.confirmDialog = false;
      this.refresh();
    } catch (err) {
      this.errorConfirm = err.message;
    }

    this.isLoadingConfirm = false;
  }

  async refresh() {
    this.isLoading = true;
    try {
      const item = await Classes.getClass({ id: this.classId });
      this.model = clone(item);
      this.setEditModel();

      const userData = await Users.listUsers({
        limit: 3000,
        offset: 0
      })
      this.students = userData.items.map((user: any) => ({
        id: user.id,
        fullName: `${user.name} (${user.email}))`
      }))
    } catch (err) {
      this.error = err.message;
    }
    this.isLoading = false;
  }
}
