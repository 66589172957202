<template lang="pug">
  div
    v-row
      v-col
        h1.mb-6 Coupons
      v-col(cols="auto")
        v-btn(text @click="$router.push('/coupons/create')")
          v-icon mdi-plus
          span Create new
    p Here you can find information about coupons and create new one.
    CouponsTable
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

import CouponsForm from '@/components/CouponsForm';
import CouponsTable from '@/components/CouponsTable';

@Component({
  name: 'Coupons',
  components: {
    CouponsForm,
    CouponsTable
  }
})
export default class Coupons extends Vue {
}
</script>