
























import { Vue, Component } from 'vue-property-decorator';
import Faq from '@/components/FAQ.vue';
import { Classes } from '@/store/modules';
import * as faqSection from '@/lib/faq';


@Component({
  name: 'FAQ',
  components: {
    Faq
  }
})
export default class extends Vue {
  loading = false;
  faqs: faqSection.Faq[] = [];
  categories: any[] = [];
  subcategories: any[] = [];
  newFaq = {
    title: '',
    description: '',
    categories: []
  }
  valid = false;
  required = (v: any) => !!v || 'Required';

  async mounted() {
    await this.refresh()
    const categories = await Classes.listCategories();
    this.categories = categories.data;
    this.categories.forEach(async (category) => {
      const subcategories = await Classes.listCategories(category.slug);
      this.subcategories.push(...subcategories.data);
    })
  }

  async refresh() {
    try {
      this.faqs = await faqSection.listFaqSections();
    } catch (err: any) {
      console.log(err.messsage)
    }
  }

  async addNew () {
    if (!(this.$refs as any).form.validate()) {
      return;
    }

    try {
      await faqSection.addSection({ categories: this.newFaq.categories, question: this.newFaq.title, answer: this.newFaq.description })
      this.refresh();
    } catch (err: any) {
      console.log(err.message);
    }
    (this.$refs as any).form.reset();
  }
}

