import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist';

import auth from './auth';
import users from './users';
import transactions from './transactions';
import teachers from './teachers';
import classes from './classes';
import coupons from './coupons';
import settings from './settings';
import seats from './seats'
import blog from './blog'

const modules = {
  auth,
  users,
  transactions,
  teachers,
  classes,
  coupons,
  settings,
  seats,
  blog
};

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export default new Vuex.Store({
  state: {},
  modules,
  plugins: [vuexLocal.plugin],
});
