<template lang="pug">
  div
    h1.mb-6 Settings
    p Here you can change some configuration. Application will respect changes after save.

    v-expand-transition
      v-progress-linear(v-if="isLoading" indeterminate)

    v-expand-transition
      v-alert(v-if="error && !isLoading" type="error" text) {{ error | APIError }}

    v-expand-transition
      div(v-if="!isLoading && !error")
        v-row
          v-col.col-auto
            v-btn(icon @click="showConfirmDialog = true")
              v-icon mdi-save
        v-form(v-model="valid" ref="form")
          v-row(no-gutters justify="space-between" align="center")
            v-col.pl-lg-1(cols="12" lg="6" hide-details)
              v-text-field(type="number" label="Chat history days" min="7" full-width disabled)
            v-col.pl-lg-1(cols="12" lg="6" hide-details)
              v-text-field(type="number" label="Chat attachment size" full-width disabled)
          v-row(no-gutters justify="space-between" align="center")
            v-col(cols="12")
              v-select(
                :loading="categoriesLoading"
                return-object
                outlined
                label="Categories without coupons"
                hide-details
                dense
                v-model="categoriesWithoutCoupons"
                multiple
                :items="categories"
                item-value="id"
                item-text="display"
              )
          v-row
            v-col(cols="12")
              v-btn(block color="primary" elevation="0" @click="save()") Submit settings
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import { Classes, Settings } from '@/store/modules';

@Component({
  name: 'Settings',
  components: {}
})
export default class SettingsPage extends Vue {
  data = {};
  isLoading = false;
  error = '';
  valid = false;
  categories = [];
  categoriesLoading = false;
  categoriesWithoutCoupons = [];

  mounted() {
    this.refresh();
  }

  async refresh() {
    await Promise.all([
      this.refreshSettings(),
      this.refreshCategories()
    ]);
  }

  async refreshSettings() {
    this.isLoading = true;
    try {
      this.date = await Settings.list();
      this.categoriesWithoutCoupons = (this.date.categoriesWithoutCoupons || []);
    } catch (error) {
      this.error = error.message;
    }
    this.isLoading = false;
  }

  async refreshCategories() {
    this.categoriesLoading = true;
    try {
      const { data } = await Classes.listAllCategories();
      this.categories = data.map(item => ({ ...item, display: [...item.path, item.id].join(' > ') }));
    } catch (error) {
      this.error = error.message;
    }
    this.categoriesLoading = false;
  }

  async save() {
    if (!this.$refs.form.validate()) {
      return;
    }

    try {
      await Settings.save({
        categoriesWithoutCoupons: this.categoriesWithoutCoupons.map(item => item.id || item)
      });
    } catch (error) {
      console.error(error.message);
    }

    this.$refs.form.reset();
    await this.refresh();
  }
}
</script>

<style scoped>

</style>
