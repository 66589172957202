
























import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';
import * as faqSection from '@/lib/faq'

@Component({
  name: 'FAQ',
})
export default class extends Vue {
  @Prop() data!: any;
  @Prop() categories!: any;
  @Prop() subcategories!: any;

  editedData = {
    answer: '',
    categories: [],
    id: '',
    question: ''
  };
  snackbar = false;
  edited = false;
  saving = false;
  required = (v: any) => !!v || 'Required';


  mounted() {
    this.editedData = this.data;
  }

  async save () {
    if (!(this.$refs as any).form.validate()) {
      return;
    }
    this.saving = true;
    try {
      await faqSection.updateSection(
        this.data.id,
      {
        answer: this.editedData.answer,
        categories: this.editedData.categories,
        question: this.editedData.question
      })
      this.snackbar = true;

      this.$emit('refresh');
      this.edited = false;
    } catch (err: any) {
      console.log(err.message);
    }
    this.saving = false;
  }

  async deleteQuestion () {
    if(!confirm('Are you sure you want to delete this section?')){
      return;
    }

    try {
      await faqSection.deleteSection(this.data.id)
    } catch(err: any) {
      console.log(err.message);
    }
    this.$emit('refresh')
  }
}

