






























import { Vue, Component, Watch } from 'vue-property-decorator';
import Avatar from '@/components/Avatar.vue';
import { Parser } from 'json2csv';

import { Teachers } from '@/store/modules';

@Component({
  components: {
    Avatar
  }
})
export default class extends Vue {
  headers = [
    { text: 'Teacher', value: 'teacher' },
    { text: 'Payout', value: 'payout', sortable: false },
    { text: 'Payout in progress', value: 'pendingPayout', sortable: false },
    { text: 'Payout Provider', value: 'payoutMethod', sortable: false },
    { text: 'Payout Acct Name', value: 'accountName', sortable: false },
    { text: 'Payout Acct Email', value: 'accountEmailId', sortable: false },
    { text: 'Actions', value: 'actions', sortable: false },
  ]
  items = [];
  count = 0;
  options: any = {};
  error = '';
  loading = false;

  mounted() {
    this.refresh();
  }

  @Watch('options', { deep: true })
  onOptionsChanged() {
    this.refresh();
  }

  async refresh() {
    this.loading = true;
    try {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let sort = sortBy[0];

      if (sort === 'teacher') {
        sort = 'name';
      }
  
      const { items, count } = await Teachers.listPayouts({
        limit: itemsPerPage,
        offset: (page - 1) * itemsPerPage,
        sort,
        sortDesc: sortDesc[0]
      });
  
      this.items = items.map((teacher: any) => {
        const payoutProfile = (teacher.payoutStatus && teacher.payoutStatus.payoutProfile) || {};

        return {
          id: teacher.id,
          avatarUrl: teacher.avatarUrl,
          name: teacher.name,
          payout: Number((teacher.payoutStatus && teacher.payoutStatus.availablePayout) || 0) - Number((teacher.payoutStatus && teacher.payoutStatus.comission) || 0),
          pendingPayout: Number((teacher.payoutStatus && teacher.payoutStatus.pendingPayout) || 0) - Number((teacher.payoutStatus && teacher.payoutStatus.payoutComission) || 0),
          payoutMethod: payoutProfile.payoutMethod || 'null',
          accountName: payoutProfile.accountName || 'null',
          accountEmailId: payoutProfile.accountEmailId || 'null'
        }
      })
      this.count = count;
    } catch (err) {
      this.error = err.message;
    }
    this.loading = false;
  }

  exportToCSV() {
    const parser = new Parser();
    const csv = parser.parse(this.items.map((item: any) => ({
      Teacher: item.name,
      Payout: item.payout,
      "Payout Provider": item.payoutMethod,
      "Payout Acct Name": item.accountName,
      "Payout Acct Email": item.accountEmailId,
    })));
    const url = window.URL.createObjectURL(new Blob([csv]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Payouts.csv');
    document.body.appendChild(link);
    link.click();
  }

  async startPayout(teacherId: string) {
    const confirmed = confirm('Are you sure to start the payout process? You will be able to cancel at any time. Rembember to finish payout after the bank transfer is successful.');

    if (!confirmed) {
      return;
    }

    try {
      await Teachers.startPayout({ teacherId });
      window.alert('Payout started');
    } catch (err) {
      window.alert('Payout failed!');
      window.alert(err.message);
    }

    window.location.reload();
  }

  async cancelPayout(teacherId: string) {
    const confirmed = confirm('Are you sure to cancel the payout process? Do this only in case the bank transfer was failed.');

    if (!confirmed) {
      return;
    }

    try {
      await Teachers.cancelPayout({ teacherId });
      window.alert('Payout canceled');
    } catch (err) {
      window.alert('Failed to cancel payout!');
      window.alert(err.message);
    }

    window.location.reload();
  }

  async finishPayout(teacherId: string) {
    const confirmed = confirm('Are you sure to finish the payout? Do this only when funds were received by the teacher. This cannot be undone.');

    if (!confirmed) {
      return;
    }

    try {
      await Teachers.finishPayout({ teacherId });
      window.alert('Payout finished!');
    } catch (err) {
      window.alert('Failed to mark payout as finished!');
      window.alert(err.message);
    }

    window.location.reload();
  }
}
