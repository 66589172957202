import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import store from '@/store/store';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: {
      title: 'Home',
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      layout: 'centered',
      title: 'Login',
      public: true,
    },
  },
  {
    path: '/classes',
    name: 'classes',
    component: () => import(/* webpackChunkName: "classes" */ '../views/Classes.vue'),
    meta: {
      title: 'Classes',
    }
  },
  {
    path: '/classes/:id',
    name: 'class-details',
    component: () => import(/* webpackChunkName: "classes" */ '../views/Classes/Class.vue'),
    meta: {
      title: 'Class Details',
    }
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue'),
    meta: {
      title: 'Users',
    }
  },
  {
    path: '/teachers',
    name: 'teachers',
    component: () => import(/* webpackChunkName: "teachers" */ '../views/Teachers.vue'),
    meta: {
      title: 'Teachers',
    }
  },
  {
    path: '/users/:id',
    name: 'user-details',
    component: () => import(/* webpackChunkName: "user" */ '../views/Users/User.vue'),
    meta: {
      title: 'User Details',
    }
  },
  {
    path: '/teachers/:id',
    name: 'teacher-details',
    component: () => import(/* webpackChunkName: "teachers" */ '../views/Teachers/Teacher.vue'),
    meta: {
      title: 'Teacher Details',
    }
  },
  {
    path: '/payments',
    name: 'payments',
    component: () => import(/* webpackChunkName: "payments" */ '../views/Payments.vue'),
    meta: {
      title: 'Payments',
    }
  },
  {
    path: '/payments/:id',
    name: 'payment-details',
    component: () => import(/* webpackChunkName: "payments" */ '../views/Payments/Payment.vue'),
    meta: {
      title: 'Payment Details',
    }
  },
  {
    path: '/payouts',
    name: 'payouts',
    component: () => import(/* webpackChunkName: "payouts" */ '../views/Payouts.vue'),
    meta: {
      title: 'Payouts',
    }
  },
  {
    path: '/coupons',
    name: 'coupons',
    component: () => import(/* webpackChunkName: "coupons" */ '../views/Coupons.vue'),
    meta: {
      title: 'Coupons'
    }
  },
  {
    path: '/coupons/create',
    name: 'coupon-create',
    component: () => import(/* webpackChunkName: "coupons" */ '../views/Coupons/Create.vue'),
    meta: {
      title: 'Coupon Create'
    }
  },
  {
    path: '/coupons/:id',
    name: 'coupon-details',
    component: () => import(/* webpackChunkName: "coupons" */ '../views/Coupons/Coupon.vue'),
    meta: {
      title: 'Coupon Details'
    }
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import(/* webpackChunkName: "payouts" */ '../views/FAQ.vue'),
    meta: {
      title: 'FAQ',
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
    meta: {
      title: 'Settings',
    }
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import(/* webpackChunkName: "settings" */ '../views/Blog/Blog.vue'),
    meta: {
      title: 'Blog',
    }
  },
  {
    path: '/blog/:id',
    name: 'blog details',
    component: () => import(/* webpackChunkName: "user" */ '../views/Blog/BlogDetails.vue'),
    meta: {
      title: 'Blog Posts Details',
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const authRequired = !(to.meta && to.meta.public);
  const loggedIn = store.getters['auth/loggedIn'];

  if (authRequired && !loggedIn) {
    const query: { [key: string]: string } = {};

    if (to.fullPath !== '/') {
      query.redirectUrl = to.fullPath;
    }

    return next({ path: '/login', query });
  }

  if (to.name === 'login' && loggedIn) {
    return next('/');
  }

  document.title = (to.meta && to.meta.title) || 'SkillToon Admin Panel';

  next();
});

export default router
