





































import Vue from 'vue';
import Component from 'vue-class-component';
import { Auth } from '@/store/modules'

@Component
export default class extends Vue {
  email: string = ''
  password: string =  ''
  show: boolean = true
  loading: boolean = false
  loginError: string = ''


  async loginSubmit () {
    this.loading = true

    try {
      await Auth.doLogin({ email: this.email, password: this.password })
      this.show = false
      setTimeout(() => {
        this.$router.push('/')
      }, 1000);
    } catch (err) {
      this.loginError = "Wrong credentials"
    }

    this.loading = false
  }

}
