import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import vuetify from './plugins/vuetify';
import './plugins/axios';
import { getErrorMessage } from '@/lib/errorMessages';

Vue.config.productionTip = false

Vue.filter('APIError', (message: any) => {
  return getErrorMessage(message);
});

Vue.filter('formatDate', (date: any) => {
  if (!date) {
    return 'No date';
  }

  const dateObj = new Date(date);
  return dateObj.toLocaleString();
});

Vue.filter('formatBoolean', (value: any) => {
  return value ? 'Yes' : 'No';
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
