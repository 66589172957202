







































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { Transactions } from '@/store/modules';
import Avatar from '@/components/Avatar.vue';

@Component({
  components: {
    Avatar
  }
})
export default class extends Vue {
  @Prop() teacherId?: string;
  @Prop() userId?: string;
  headers = [
    { text: 'Transaction ID', value: 'transactionId' },
    { text: 'Amount USD', value: 'fullPrice' },
    { text: 'Amount paid', value: 'price' },
    { text: 'Status', value: 'status' },
    { text: 'Teacher', value: 'teacher', width: '180' },
    { text: 'User', value: 'user', width: '180' },
    { text: 'Class', value: 'class' },
    { text: 'Date', value: 'issuedAt' },
    { text: 'Paid out date', value: 'paidOutAt' },
    { text: 'Payment provider', value: 'paymentProvider' },
  ];
  isLoading = false;
  totalPayments = 0;
  options: any = {};
  payments: any[] = [];
  error = '';

  mounted() {
    this.refresh();
  }

  @Watch('options', { deep: true })
  onOptionsChanged() {
    this.refresh();
  }

  async refresh() {
    try {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;

      const { items, count } = await Transactions.listTransactions({
        limit: itemsPerPage,
        offset: (page - 1) * itemsPerPage,
        teacherId: this.teacherId,
        userId: this.userId,
        sort: sortBy,
        sortDesc: sortDesc
      });
      console.log('items ', items)
      console.log('count ', count)
      this.payments = items;
      this.totalPayments = count;
    } catch (err) {
      this.error = err.message;
    }
  }
}
