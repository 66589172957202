import Vue from 'vue';
import { Module, VuexModule, Action } from 'vuex-module-decorators'

export type EditTeacherModel = {
  email: string
  name: string
  details: {
    youtube?: string
    instagram?: string
    facebook?: string
    twitter?: string
    bio?: string
    location?: string
    termsConditions?: boolean
    privacyPolicy?: boolean
    marketing?: boolean
    onboardingFinished?: boolean
  }
  role?: string
}

@Module({ namespaced: true, name: 'teachers', preserveState: true  })
class Teachers extends VuexModule {

  @Action({ rawError: true })
  async listTeachers(data: { limit: number, offset: number, role?: string, sort?: string, sortDesc?: boolean }) {
    const query = new URLSearchParams();

    if (data.limit) {
      query.append('limit', `${data.limit}`);
    }

    if (data.offset) {
      query.append('offset', `${data.offset}`);
    }

    if (data.sort) {
      query.append('sort', `${data.sort}`);
    }

    if (data.sortDesc) {
      query.append('sortDesc', `${data.sortDesc}`);
    }

    const response = await Vue.$axios.get(`/teachers?${query.toString()}`);
    return response.data;
  }

  @Action({ rawError: true })
  async listPayouts(data: { limit: number, offset: number, role?: string, sort?: string, sortDesc?: boolean }) {
    const query = new URLSearchParams();

    if (data.limit) {
      query.append('limit', `${data.limit}`);
    }

    if (data.offset) {
      query.append('offset', `${data.offset}`);
    }

    if (data.sort) {
      query.append('sort', `${data.sort}`);
    }

    if (data.sortDesc) {
      query.append('sortDesc', `${data.sortDesc}`);
    }

    const response = await Vue.$axios.get(`/teachers/payouts?${query.toString()}`);
    return response.data;
  }

  @Action({ rawError: true })
  async startPayout(data: { teacherId: string }) {
    const response = await Vue.$axios.post(`/teachers/${data.teacherId}/start-payout`);
    return response.data;
  }

  @Action({ rawError: true })
  async cancelPayout(data: { teacherId: string }) {
    const response = await Vue.$axios.post(`/teachers/${data.teacherId}/cancel-payout`);
    return response.data;
  }

  @Action({ rawError: true })
  async finishPayout(data: { teacherId: string }) {
    const response = await Vue.$axios.post(`/teachers/${data.teacherId}/finish-payout`);
    return response.data;
  }

  @Action({ rawError: true })
  async getTeacher(data: { id: string }) {
    const response = await Vue.$axios.get(`/teachers/${data.id}`);
    return response.data;
  }

  @Action({ rawError: true })
  async editTeacher(data: { id: string, payload: EditTeacherModel }) {
    return await Vue.$axios.patch(`/teachers/${data.id}`, data.payload);
  }

  @Action({ rawError: true })
  async deleteTeacher(data: { id: string }) {
    return await Vue.$axios.delete(`/teachers/${data.id}`);
  }
}

export default Teachers;
