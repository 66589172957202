import Vue from 'vue';
import { Action, Module, VuexModule } from 'vuex-module-decorators'

export type EditClassModel = {
  email: string
  name: string
  details: {
    youtube?: string
    instagram?: string
    facebook?: string
    twitter?: string
    bio?: string
    location?: string
    termsConditions?: boolean
    privacyPolicy?: boolean
    marketing?: boolean
    onboardingFinished?: boolean
  }
  role?: string
}

@Module({ namespaced: true, name: 'classes', preserveState: true })
class Classes extends VuexModule {

  @Action({ rawError: true })
  async listClasses(data: { limit: number, offset: number, teacherId?: string, userId?: string, sort?: string, sortDesc?: boolean }) {
    const query = new URLSearchParams();

    if (data.limit) {
      query.append('limit', `${data.limit}`);
    }

    if (data.offset) {
      query.append('offset', `${data.offset}`);
    }

    if (data.teacherId) {
      query.append('teacherId', `${data.teacherId}`);
    }

    if (data.userId) {
      query.append('userId', `${data.userId}`);
    }

    if (data.sort) {
      query.append('sort', `${data.sort}`);
    }

    if (data.sortDesc) {
      query.append('sortDesc', `${data.sortDesc}`);
    }

    const response = await Vue.$axios.get(`/classes?includeHidden=true&${query.toString()}`);
    return response.data;
  }

  @Action({ rawError: true })
  async getClass(data: { id: string }) {
    const response = await Vue.$axios.get(`/classes/${data.id}/full`);
    return response.data;
  }

  @Action({ rawError: true })
  async editClass(data: { id: string, payload: EditClassModel }) {
    return await Vue.$axios.patch(`/classes/${data.id}`, data.payload);
  }

  @Action({ rawError: true })
  async deleteClass(data: { id: string }) {
    return await Vue.$axios.delete(`/classes/${data.id}`);
  }

  @Action({ rawError: true })
  async cancelClass(data: { id: string }) {
    return await Vue.$axios.post(`/classes/${data.id}/cancel`);
  }

  @Action({ rawError: true })
  async listCategories(path?: string) {
    const query = new URLSearchParams();
    if (path) {
      query.append('path', path);
      return await Vue.$axios.get(`/categories?${query}`);
    }
    return await Vue.$axios.get('/categories');
  }

  @Action({ rawError: true })
  async listAllCategories() {
    const query = new URLSearchParams({
      all: 'true'
    });
    return Vue.$axios.get(`/categories?${query}`);
  }
}

export default Classes;
