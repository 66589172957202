








































import Vue from "vue";
import Component from "vue-class-component";

import { Auth } from '@/store/modules';

@Component({ name: "Layout" })
export default class extends Vue {
  drawer: boolean = true;
  menu: any[] = [
    { text: "Home", icon: "mdi-home", to: "/" },
    { text: "Classes", icon: "mdi-chair-school", to: "/classes" },
    { text: "Payments", icon: "mdi-cash", to: "/payments" },
    { text: "Payouts", icon: "mdi-bank-transfer-out", to: "/payouts" },
    { text: "Users", icon: "mdi-account-group", to: "/users" },
    { text: "Teachers", icon: "mdi-school", to: "/teachers" },
    { text: "Coupons", icon: "mdi-ticket", to: "/coupons" },
    { text: "FAQ", icon: "mdi-account-question", to: "/FAQ" },
    { text: "Settings", icon: "mdi-cog", to: "/settings" },
    { text: "Blog", icon: "mdi-post", to: "/blog" },
  ];

  doLogout() {
    Auth.doLogout();
  }

  get loggedIn() {
    return true;
  }

  get name() {
    return "Test";
  }

  get email() {
    return "test@example.com";
  }

  get picture() {
    return "";
  }

  get title() {
    return (this.$route.meta && this.$route.meta.title) || "SkillToon";
  }
}
