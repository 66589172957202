















































































































































































import Vue from "vue";
import { Component, Ref } from "vue-property-decorator";
import { VForm } from "@/lib/types";
import { clone, pick, keys, omit, none } from "ramda";

import ConfirmDialog from "@/components/ConfirmDialog.vue";
import Avatar from "@/components/Avatar.vue";
import axios from "axios";
import { randomBytes } from 'crypto';


import { Auth, Blog } from "@/store/modules";
import { User } from "@/store/auth";
import DatePicker from '@/components/DatePicker.vue';


@Component({
    name: "Class",
    components: {
        ConfirmDialog,
        Avatar,
        DatePicker
    },
})
export default class extends Vue {
    @Ref("form") readonly form!: VForm;
    @Ref("addBlogForm") readonly addBlogForm!: VForm;

    blogHeaders = [
        { text: "Title", value: "title" },
        { text: "Category", value: "category" },
        { text: "Author", value: "author" },
        { text: "Date", value: "date" },
    ];
    model = []
    isLoading = false;
    isLoadingEdit = false;
    isLoadingConfirm = false;
    error = "";
    editError = "";
    errorConfirm = "";
    showEditDialog = false;
    confirmDialog = false;
    confirmDialogHeader = "";
    confirmDialogContent = "";
    showAddBlogDialog = false;
    addBlogDialogModel: any = {
        category: '',
        image: '',
        id: '',
        date: '',
        description: '',
        title: ''
    };
    inputPhotos = this.addBlogDialogModel.image;
    defaultUploadId = `${(Auth.user as any).teacherId}-${Date.now()}`;
    isFileInputLoading = false;
    addBlogPostError: string = "";
    isAddBlogPostLoading: boolean = false;
    valid = true;
    addBlogValid = true;
    deleteAcion = false;
    deleteId = '';
    editModel: any = {
        name: null,
        maxSeats: null,
        isOnline: null,
        isHidden: null,
        featured: null,
        details: {},
    };
    students: {
        id: string
        fullName: string
    }[] = []

    mounted() {
        this.refresh();
    }

    get classId() {
        return this.$route.params.id;
    }

    requirements() {
        return (
            (this.editModel &&
                this.editModel.details &&
                this.editModel.details.requirements &&
                this.editModel.details.requirements.split("\n")) ||
            []
        );
    }

    executeClassAction() {
        this.deleteBlogPost();
    }

    async deleteBlogPost() {
        await Blog.deleteBlogPosts(this.deleteId)
        this.refresh()
    }

    showConfirmDialog(model: any) {
        console.log('model ', model)
        this.deleteAcion = true;
        this.confirmDialogHeader = "Delete post";
        this.confirmDialogContent = "Are you sure you want to delete this post?";
        this.deleteId = model.id

        this.confirmDialog = true;
    }

    cancelEdit() {
        this.showEditDialog = false;
        this.editError = "";
    }

    cancelAddStudent() {
        this.showAddBlogDialog = false;
        this.addBlogDialogModel.user = undefined;
    }

    get uploadId() {
        return this.addBlogDialogModel.id || this.defaultUploadId;
    }


    async handleFileChange(file: File) {
        this.inputPhotos = file
        this.isFileInputLoading = true

        if (!file) {
            return;
        }

        if (file.size > 1024 * 1024 * 5) {  // 5mb
            alert('File size cannot exceed 5 megabytes!');
            return;
        }

        const uploadUrl = await Auth.getUploadUrl({
            type: "blog_image",
            id: `${this.uploadId}-${randomBytes(2).toString('hex')}`,
        });

        const url = `${uploadUrl.substr(0, uploadUrl.indexOf('?'))}`;

        await axios.put(uploadUrl, file, {
            headers: {
                'Content-Type': file.type || 'image/jpeg',
            }
        });


        this.isFileInputLoading = false
        this.addBlogDialogModel.image = url
    }

    async addBlogPost() {
        this.isAddBlogPostLoading = true;
        this.addBlogPostError = ""

        if (this.addBlogForm.validate()) {
            try {
                await Blog.addBlogPosts(this.addBlogDialogModel)
                this.refresh()
            } catch (err) {
                this.editError = err.message
            }
        } else {
            this.isAddBlogPostLoading = false;
            this.showAddBlogDialog = true;
            return;
        }

        this.isAddBlogPostLoading = false;
        this.showAddBlogDialog = false;
        this.addBlogDialogModel = {}
    }

    async refresh() {
        this.isLoading = true;
        try {
            const item = await Blog.getBlogPosts()
            console.log('item ', item)
            this.model = clone(item);

            console.log('this moooodel', this.model)
        } catch (err) {
            // this.error = err.message;
            this.isLoading = false;
        }
        this.isLoading = false;
    }
}
