








import { Vue, Component } from 'vue-property-decorator';

import TransactionsTable from '@/components/TransactionsTable.vue';

@Component({
  name: 'Payments',
  components: {
    TransactionsTable
  }
})
export default class extends Vue {
}
