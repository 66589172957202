







import { Vue, Component } from 'vue-property-decorator';

import ClassesTable from '@/components/ClassesTable.vue';

@Component({
  components: {
    ClassesTable
  }
})
export default class extends Vue {
}
