import Vue from 'vue';
import { Action, Module, VuexModule } from "vuex-module-decorators";

@Module({ namespaced: true, name: 'seats', preserveState: true })
class Seats extends VuexModule {
    @Action({ rawError: true })
    async reserveSeatForUser({ classId, id }: { classId: string, id: string }) {
        console.log('classId', classId, 'id', id);
        return await Vue.$axios.post(`/classes/${classId}/seats`, {
            userId: id
        });
    }
}

export default Seats;