import { render, staticRenderFns } from "./TransactionsTable.vue?vue&type=template&id=6bcbe074&scoped=true&lang=pug&"
import script from "./TransactionsTable.vue?vue&type=script&lang=ts&"
export * from "./TransactionsTable.vue?vue&type=script&lang=ts&"
import style0 from "./TransactionsTable.vue?vue&type=style&index=0&id=6bcbe074&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bcbe074",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VAlert,VDataTable,VExpandTransition})
