import Vue from 'vue';
import { Module, VuexModule, Action } from 'vuex-module-decorators'

@Module({ namespaced: true, name: 'transactions', preserveState: true  })
class Transactions extends VuexModule {

  @Action({ rawError: true })
  async listTransactions(data: { limit: number, offset: number, teacherId?: string, userId?: string, sort?: string, sortDesc?: boolean }) {
    const query = new URLSearchParams();

    if (data.limit) {
      query.append('limit', `${data.limit}`);
    }

    if (data.offset) {
      query.append('offset', `${data.offset}`);
    }

    if (data.teacherId) {
      query.append('teacherId', `${data.teacherId}`);
    }

    if (data.userId) {
      query.append('userId', `${data.userId}`);
    }

    if (data.sort) {
      query.append('sort', `${data.sort}`);
    }

    if (data.sortDesc) {
      query.append('sortDesc', `${data.sortDesc}`);
    }
    console.log('data', data)
    console.log('query ', query);
    const response = await Vue.$axios.get(`/transactions?${query.toString()}`);
    return response.data;
  }

  @Action({ rawError: true })
  async getTransaction(data: { id: string }) {
    const response = await Vue.$axios.get(`/transactions/${data.id}`);
    return response.data;
  }
}

export default Transactions;
