<template lang="pug">
  div
    h1.mb-6 Create coupon
    CouponsForm
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import CouponsForm from '@/components/CouponsForm';

@Component({
  name: 'CouponCreate',
  components: {
    CouponsForm
  }
})
export default class CouponCreate extends Vue {
}
</script>
