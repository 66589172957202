import Vue from 'vue';
import { Action, Module, VuexModule } from 'vuex-module-decorators';

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
interface ISettings {
  chatHistoryDays: number
  chatAttachmentSizeLimitMb: number
  categoriesWithoutCoupons: string[]
}

@Module({ namespaced: true, name: 'settings', preserveState: true })
export default class Settings extends VuexModule {
  @Action({ rawError: true })
  async list() {
    const response = await Vue.$axios.get(`/settings`);
    return response.data;
  }

  @Action({ rawError: true })
  async save(data: Partial<ISettings>) {
    return Vue.$axios.patch(`/settings`, data);
  }
}