import { getModule } from 'vuex-module-decorators';

import store from './store';

import auth from './auth';
import users from './users';
import transactions from './transactions';
import teachers from './teachers';
import classes from './classes';
import coupons from './coupons';
import settings from './settings';
import seats from './seats';
import blog from './blog';

export const Auth = getModule(auth, store);
export const Users = getModule(users, store);
export const Transactions = getModule(transactions, store);
export const Teachers = getModule(teachers, store);
export const Classes = getModule(classes, store);
export const Coupons = getModule(coupons, store);
export const Settings = getModule(settings, store);
export const Seats = getModule(seats, store);
export const Blog = getModule(blog, store);