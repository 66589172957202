







import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ name: "Login" })
export default class DatePicker extends Vue {
  @Prop() value: any;
  @Prop() outlined?: boolean;
  @Prop() label?: string;
  @Prop() color?: string;
  @Prop() solo?: boolean;
  @Prop() rules?: any[];
  @Prop() disabled?: boolean;
  @Prop() dense?: boolean;

  menu = false;
  model = '';

  mounted() {
    this.model = this.value;
  }

  save() {
    this.$emit('input', this.model);
    this.menu = false;
  }
}
