








































































import Vue from 'vue';
import { Component } from "vue-property-decorator";

import Avatar from '@/components/Avatar.vue'

import { Transactions } from '@/store/modules';


export type MaterialsShippingAddress = {
  name: string
  mobileNumber: string
  postalCode: string
  building: string
  street: string
  city: string
  state: string
  country: string
}

@Component({
  name: 'Payment',
  components: {
    Avatar
  }
})
export default class extends Vue {

  payment: any = {};
  isLoading = true;
  error = '';
  shippingAddress: MaterialsShippingAddress | undefined = undefined

  mounted() {
    this.refresh();
  }

  get paymentId() {
    return this.$route.params.id;
  }

  async refresh() {
    this.isLoading = true;
    try {
      this.payment = await Transactions.getTransaction({ id: this.paymentId });
      if (this.payment.materialsShippingAddress) {
        this.shippingAddress = this.payment.materialsShippingAddress
      }
    } catch (err: any) {
      this.error = err.message;
    }
    this.isLoading = false;
  }
}
